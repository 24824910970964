import styled from "styled-components";
import { Link } from "gatsby";

export const Container = styled.nav`
  background-color: #ffffff;
  position: sticky;
  top: 0;
  height: 60px;
  z-index: 999;
  align-items: center;
  box-shadow: 0px 2px 35px rgba(215, 215, 215, 0.15);
  vertical-align: middle;
`;
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  vertical-align: middle;
  min-height: 60px;
  justify-content: space-between;
  max-width: 78.75rem;
  margin: 0 auto;
`;

export const LinkInterno = styled(Link)`
  color: #000000;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.976rem;

  &.active {
    > span {
      border-bottom: 2px solid #6ed796;
    }
  }

  @media screen and (min-width: 1000px) {
    padding: 0px 20px;
  }
`;
export const LinkExterno = styled.a`
  color: #000000;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.976rem;

  @media screen and (min-width: 1000px) {
    padding: 0px 20px;
  }
`;

export const Divider = styled.span`
  border-left: 1px solid #bfbfbf;
  padding-left: 1rem;
`;

export const Open = styled.div<{ open: boolean }>`
  -webkit-overflow-scrolling: touch;
  width: 1.3rem;
  height: 1.3rem;
  position: relative;
  right: 25px;
  z-index: 20;
  display: none;
  @media (max-width: 1000px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 1.3rem;
    height: 0.1rem;
    background-color: ${({ open }) => (open ? "#404040" : "#000000")};
    border-radius: 50px;
    transform-origin: 0.5px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
  @media screen and (min-width: 1000px) {
    display: none;
  }
`;

export const Logo = styled.img`
  width: 7.7rem;
  height: auto;
  padding-left: 20px;
  @media screen and (min-width: 600px) {
    width: 9rem;
  }
`;

export const Menu = styled.div`
  display: none;

  @media screen and (min-width: 1000px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 40px;
    gap: 0;
  }
`;

export const ButtonWrapper = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavButton = styled.a`
  background-color: #6ed796;
  color: #000000;
  padding: 12px 20px;
  border-radius: 2px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9rem;

  &:hover,
  &:focus {
    background-color: #000000;
    color: #ffffff;
  }
`;
