import React from "react";
import {
  Container,
  Wrapper,
  LinkInterno,
  Logo,
  Open,
  Menu,
  NavButton,
  Divider,
  LinkExterno,
} from "./nav.styles";
interface Props {
  toggle: any;
  open: boolean;
}

const Nav: React.FC<Props> = ({ toggle, open }) => {
  return (
    <Container>
      <Wrapper>
        <LinkInterno as="a" href="https://www.abbiamolog.com/">
          <Logo src="/Abbiamo_Logo.svg" />
        </LinkInterno>
        <Open onClick={toggle} open={open}>
          <div />
          <div />
          <div />
        </Open>
        <Menu>
          <LinkInterno to="/blog/" activeClassName="active">
            <span>Blog</span>
          </LinkInterno>
          <LinkInterno
            as="a"
            href="https://www.abbiamolog.com/#contato"
            activeClassName="active">
            <span>Contato</span>
          </LinkInterno>
          <Divider>
            <LinkExterno href="https://dashboard.abbiamolog.com/login">
              Login
            </LinkExterno>
          </Divider>
          <NavButton as="a" href="https://www.abbiamolog.com/#contato">
            Agende um teste
          </NavButton>
        </Menu>
      </Wrapper>
    </Container>
  );
};

export default Nav;
