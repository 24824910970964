import React from "react";
import {
  Container,
  Wrapper,
  Menu,
  List,
  Link,
  ExternalLink,
  Text,
} from "./sidebar.styles";
import { useLockBodyScroll } from "react-use";

interface Props {
  open: boolean;
  toggle: any;
}

const Sidebar: React.FC<Props> = ({ open, toggle }) => {
  useLockBodyScroll(open);

  return (
    <Container open={open} onClick={toggle}>
      <Wrapper>
        <Menu>
          <List>
            <Link to="/blog/">Blog</Link>
            <Link to="/agende-um-teste">Entre em contato</Link>
            <ExternalLink
              href="https://dashboard.abbiamolog.com/register"
              target="__blank">
              Agende um teste
            </ExternalLink>
          </List>
        </Menu>
        <Text>©2021 Abbiamo</Text>
      </Wrapper>
    </Container>
  );
};

export default Sidebar;
