import styled, { css } from "styled-components";
import { GrFormClose } from "@react-icons/all-files/gr/GrFormClose";
import { Link as MenuLink } from "gatsby";

export const Container = styled.aside<{ open: boolean }>`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: grid;
  align-items: start;
  top: 56px;
  left: 0;
  transition: 0.4s ease-in-out;
  left: ${({ open }) => (open ? "0" : "100%")};
  opacity: ${({ open }) => (open ? "100" : "0")};
  @media screen and (min-width: 770px) {
    display: none;
  }
`;

export const Close = styled(GrFormClose)`
  color: #000000;
`;

export const Icon = styled.button`
  position: absolute;
  text-decoration: none;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2.3rem;
  outline: 0;
  border: 0;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  background-color: #ffffff;

  @media screen and (min-width: 700px) {
    padding: 2rem 0.5rem;
  }
`;

export const Menu = styled.ul`
  padding: 2rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 60px);
  text-align: start;
`;

export const List = styled.li`
  list-style: none;
  padding-left: 1.3rem;
`;

export const LinkStyles = css`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  font-size: 1.15em;
  text-decoration: none;
  color: #000000;
  list-style: none;
  transition: 0.2 ease-in-out;
  cursor: pointer;
  padding: 1.25rem 0;

  &:hover {
    color: #6ed796;
  }

  @media screen and (min-width: 700px) {
    font-size: 1.35em;
  }
`;

export const Link = styled(MenuLink)`
  ${LinkStyles};
`;

export const ExternalLink = styled.a`
  ${LinkStyles};
`;

export const Text = styled.p`
  color: #818181;
  bottom: 3rem;
  font-size: 0.9rem;
  padding: 0.7rem 1.8rem;
`;

export const Logo = styled.img`
  width: 7.854rem;
  height: auto;
  margin-top: 5px;
  padding: 1.3rem 1.8rem;

  @media screen and (min-width: 700px) {
    width: 9.854rem;
    padding: 1.3rem 1.5rem;
  }
`;
