import React from "react";
import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: "EuropaBold";
  src: url("/fonts/EuropaBold.otf");
}  
@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt Regular.ttf");
}  

* {
    box-sizing: border;
    margin: 0;
    padding: 0;
    font-family: "EuropaBold", sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  p{

    @media screen and (min-width: 1000px){
      font-size: 1.189rem;
    }
    @media screen and (min-width: 3000px){
      font-size: 1.4rem;
    }
  }

  .MuiTypography-root {
    font-family: "EuropaBold", sans-serif !important;
    letter-spacing: 0 !important;
    font-size: 15px !important;
  }

 

`;

const Layout: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <script
        type="text/javascript"
        async
        src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/f4335181-38f2-4fbd-a21f-979afb18eebe-loader.js"></script>
      <GlobalStyles />
      {children}
    </React.Fragment>
  );
};

export default Layout;
